<template>
  <div class="index">
    <div class="header">
      <!-- 头部nav -->
      <headerNav></headerNav>
      <!-- 头部导航 -->
      <div class="tabs-nav-box">
        <div class="tabs-nav flex container">
          <div
            class="tabs-nav-item"
            v-for="(item, index) in navList1"
            :key="index"
          >
            <div
              class="nav-text"
              @click="navItemClick(index)"
              :style="current === index ? tabsStyle : ''"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>

      <!-- 新闻 -->
      <div class="news-content">
        <div class="news-title flex align-center justify-center">
          <div class="news-text">今日の主要ニュース</div>
          <div
            class="more-news"
            style="cursor: pointer"
            @click="mainNewsMoreClick"
          >
            MORE >>
          </div>
        </div>
        <div class="news-content-box flex">
          <div class="news-left flex justify-center align-center hidden-xs">
            <div class="news-left-content">
              <!-- 左边新闻 -->
              <div class="left-news1 flex-direction align-center">
                <div class="news1-title" style="cursor: pointer">
                  {{ titleList && titleList[0] && titleList[0].title }}
                </div>
                <div>
                  <div class="news1-content" v-if="topicNews.length">
                    <div
                      class="flex news1-items"
                      v-for="(item, index) in topicNews"
                      :key="index"
                      @click="hotNewsClick(index)"
                      style="cursor: pointer"
                    >
                      <img src="../assets/images/burn.png" alt="" />
                      <div>{{ item.title }}</div>
                    </div>
                  </div>
                  <div
                    class="flex-direction align-center justify-center"
                    style="height: 200px"
                    v-else
                  >
                    <img
                      style="width: 100px; height: 100px"
                      src="../assets/images/no-data.png"
                      alt=""
                    />
                    <div style="margin-top: 20px">データがありません</div>
                  </div>
                </div>
                <img
                  class="news"
                  :src="messagesList.length && messagesList[0].image"
                  style="width: 294px; height: 210px"
                />
              </div>
              <!-- 焦点话题 -->
              <div class="left-news1 flex-direction align-center">
                <div class="news1-title" style="cursor: pointer">
                  {{ titleList && titleList[1] && titleList[1].title }}
                </div>
                <div>
                  <div class="news1-content" v-if="focusNews.length">
                    <div
                      class="flex news1-items"
                      v-for="(item, index) in focusNews"
                      style="cursor: pointer"
                      :key="index"
                      @click="focusNewsClick(index)"
                    >
                      <img src="../assets/images/collect.png" alt="" />
                      <div>{{ item.title }}</div>
                    </div>
                  </div>
                  <div
                    class="flex-direction align-center justify-center"
                    style="height: 200px"
                    v-else
                  >
                    <img
                      style="width: 100px; height: 100px"
                      src="../assets/images/no-data.png"
                      alt=""
                    />
                    <div style="margin-top: 20px">データがありません</div>
                  </div>
                </div>
                <img
                  class="news"
                  :src="messagesList.length && messagesList[1].image"
                  style="width: 294px; height: 210px"
                  alt=""
                />
              </div>
              <!-- 公告 -->
              <div class="left-news1 flex-direction align-center">
                <div
                  class="news1-title"
                  style="cursor: pointer"
                  @click="goCompanyNoticeList"
                >
                  {{ titleList && titleList[3] && titleList[3].title }}
                </div>
                <div>
                  <div class="news1-content" v-if="companyNotice.length">
                    <div
                      class="news1-items flex align-center"
                      style="cursor: pointer"
                      v-for="(item, index) in companyNotice"
                      :key="index"
                      @click="goCompanyNoticeDetail(index)"
                    >
                      <img src="../assets/images/collect.png" alt="" />
                      <div>{{ item.name }}</div>
                    </div>
                  </div>
                  <div
                    class="flex-direction align-center justify-center"
                    style="height: 200px"
                    v-else
                  >
                    <img
                      style="width: 100px; height: 100px"
                      src="../assets/images/no-data.png"
                      alt=""
                    />
                    <div style="margin-top: 20px">データがありません</div>
                  </div>
                  <img
                    class="news"
                    :src="messagesList.length && messagesList[3].image"
                    style="width: 294px; height: 210px"
                    alt=""
                  />
                </div>
                <!-- 会社案内 -->
                <div class="left-news1 flex-direction w100">
                  <div
                    class="news1-title"
                    style="cursor: pointer"
                    @click="goCompanyDetail()"
                  >
                    {{ titleList && titleList[2] && titleList[2].title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="news-right" v-loading="isLoading">
            <!-- 电脑屏幕可见 -->
            <div
              class="flex justify-between hidden-xs"
              style="padding-bottom: 20px"
            >
              <div style="flex: 1">
                <div
                  class="main-news"
                  v-if="mainNews.length"
                  style="padding-bottom: 20rpx"
                >
                  <div
                    class="news-item"
                    v-for="(item, index) in mainNews"
                    :key="index"
                    style="cursor: pointer"
                    @click="mainNewsClick(index)"
                  >
                    {{ item.title }}
                  </div>
                </div>
                <div
                  class="flex-direction align-center justify-center"
                  style="height: 200px"
                  v-else
                >
                  <img
                    style="width: 100px; height: 100px"
                    src="../assets/images/no-data.png"
                    alt=""
                  />
                  <div style="margin-top: 20px">データがありません</div>
                </div>
              </div>
              <img
                style="width: 260px; height: 260px; margin-right: 40px"
                :src="imgs"
                alt=""
              />
            </div>
            <!-- 手机可见 -->
            <div class="flex-direction visible-xs">
              <div style="flex: 1">
                <div class="main-news" v-if="mainNews.length">
                  <div
                    class="news-item"
                    v-for="(item, index) in mainNews"
                    :key="index"
                    @click="mainNewsClick(index)"
                  >
                    {{ item.title }}
                  </div>
                </div>
                <div
                  class="flex-direction align-center justify-center"
                  style="height: 200px"
                  v-else
                >
                  <img
                    style="width: 100px; height: 100px"
                    src="../assets/images/no-data.png"
                    alt=""
                  />
                  <div style="margin-top: 20px">データがありません</div>
                </div>
              </div>
              <img
                style="
                  width: 260px;
                  height: 260px;
                  margin-bottom: 10px;
                  margin-left: 10px;
                "
                :src="imgs"
                alt=""
              />
            </div>
            <!-- 普通新闻 -->
            <div
              class="right-news"
              v-for="(item, index) in rightNews"
              :key="index"
            >
              <div class="right-news-title flex align-center">
                <div class="title">{{ item.name }}</div>
                <div
                  class="more"
                  style="cursor: pointer"
                  @click="newsMoreClick(index)"
                >
                  MORE >>
                </div>
              </div>
              <!-- 电脑可见 -->
              <div
                class="main-news flex hidden-xs justify-between"
                style="min-height: 200px"
              >
                <div v-if="item.news.length !== 0">
                  <div
                    class="news-item"
                    v-for="(n, i) in item.news"
                    :key="i"
                    style="cursor: pointer"
                    @click="newsItemClick(index, i)"
                  >
                    {{ n.title }}
                  </div>
                </div>

                <div
                  class="flex-direction align-center justify-center"
                  style="height: 200px; width: 100%"
                  v-else
                >
                  <img
                    style="width: 100px; height: 100px"
                    src="../assets/images/no-data.png"
                    alt=""
                  />
                  <div style="margin-top: 20px">データがありません</div>
                </div>
                <!-- <img style="width:20vw;height:20vw; padding:20px 0" :src="item.icon" alt=""> -->
              </div>
              <!-- 手机可见 -->
              <div class="main-news flex-direction visible-xs">
                <div>
                  <div
                    class="news-item"
                    v-for="(n, i) in item.news"
                    :key="i"
                    @click="newsItemClick(index, i)"
                  >
                    {{ n.title }}
                  </div>
                </div>
                <!-- <div style="text-align:center">
                         <img style="width:80vw;height:40vw; padding:20px 0;" :src="item.icon" alt="">
                    </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foolter></foolter>
  </div>
</template>

<script>
import headerNav from "../components/header-nav.vue";
import foolter from "../components/foolter.vue";
// import imgs from '../assets/images/collect1.png'
import {
  postRegLogin,
  newsList,
  getImportNews,
  getIndexImg,
  getFocusNews,
  getTopicNews,
  getcompanyProfile,
  getImagesData,
  getTitles,
} from "@/api/api.js";
export default {
  components: {
    headerNav,
    foolter,
  },

  data() {
    return {
      // 非会员可看列表
      navList1: [],
      checkboxGroup1: [],
      dialogVisible: false, //弹框
      current: 0,
      tabsStyle: "color:#004198;",
      state: "",
      focusNews: [],
      topicNews: [],
      isVip: true,
      imgs: "",
      mainNews: [],
      rightNews: [],
      newsNavId: "",
      detailNewsId: "",
      isLoading: false,
      token: "",
      companyProfile: [],
      companyNotice: [],
      messagesList: [],
      titleList: [],
    };
  },
  created() {
    this.init(); //新闻分类
    this.getnewsList(); //首页一般新闻
    this.getImportantNews();
    this.getImg();
    this.getIndexFocusNews();
    this.getIndexTopicNews();
    this.token = localStorage.getItem("token");
    this.getcompanyProfiles();
    this.getcompanyNotice();
    this.getImages();
    this.titles();
    // console.log(this.token)
    // console.log(window.innerWidth)
  },
  methods: {
    //   获取标题
    async titles() {
      const res = await getTitles();
      if (res.status == 1) {
        console.log(res);
        this.titleList = res.data;
      }
    },
    // -----------------------------详情页-------------

    // 热点新闻点击进入详情页
    hotNewsClick(index) {
      if (this.$isLogin()) {
        this.detailNewsId = this.topicNews[index].id;
        this.$router.push({
          path: "/newsDetail",
          query: { id: this.detailNewsId, detail: 2 },
        });
      }
    },
    // 焦点新闻点击进入详情页
    focusNewsClick(index) {
      if (this.$isLogin()) {
        this.detailNewsId = this.focusNews[index].id;
        this.$router.push({
          path: "/newsDetail",
          query: { id: this.detailNewsId, detail: 2 },
        });
      }
    },
    // 公司简介点击进入详情页
    goCompanyDetail() {
      this.$router.push({
        path: "/newsDetail",
        query: { detail: "company", detail: 1 },
      });
    },
    // 公司公告点击进入详情页
    goCompanyNoticeDetail(index) {
      this.detailNewsId = this.companyNotice[index].id;
      this.$router.push({
        path: "/newsDetail",
        query: { id: this.detailNewsId, detail: 2 },
      });
    },
    // 重要新闻点击进入详情页
    mainNewsClick(index) {
      if (this.$isLogin()) {
        this.detailNewsId = this.mainNews[index].id;
        this.$router.push({
          path: "/newsDetail",
          query: { id: this.detailNewsId, detail: 2 },
        });
      }
    },
    // 普通新闻点进入详情页
    newsItemClick(index, i) {
      if (this.$isLogin()) {
        this.detailNewsId = this.rightNews[index].news[i].id;
        this.$router.push({
          path: "/newsDetail",
          query: { id: this.detailNewsId, detail: 2 },
        });
      }
    },
    // -------------------------------------------列表页--------------
    // nav点击新闻分类进入列表页
    navItemClick(index) {
      this.current = index;
      this.newsNavId = this.navList1[index].id;
      this.$router.push({
        path: "/news",
        query: { Id: this.newsNavId, type: "common" },
      });
    },
    // 普通新闻MORE点击进入列表页
    newsMoreClick(index) {
      this.newsNavId = this.rightNews[index].id;
      this.$router.push({
        path: "/news",
        query: { Id: this.newsNavId, type: "common" },
      });
    },

    // 重要新闻点击进入列表页
    mainNewsMoreClick() {
      this.$router.push({
        path: "/news",
        query: { type: "import" },
      });
    },
    // hot话题新闻点击进入列表页
    goHotNewsClick() {
      this.$router.push({
        path: "/news",
        query: { type: "hot" },
      });
    },
    // 焦点特辑点击进入列表页
    goFocusNewsListClick() {
      this.$router.push({
        path: "/news",
        query: { type: "focus" },
      });
    },
    // 点击去公司详情列表页
    goProfileList() {
      this.$router.push({
        path: "/news",
        query: { type: "company" },
      });
    },
    goCompanyNoticeList() {
      this.$router.push({
        path: "/news",
        query: { type: "Notice" },
      });
    },
    // 获取公司详情接口
    async getcompanyProfiles() {
      const res = await getcompanyProfile();
      if (res.status == 1) {
        // console.log(res.data.data)
        this.companyProfile = res.data.data;
      }
    },
    // 获取公司公告接口
    async getcompanyNotice() {
      const res = await getcompanyProfile({
        type: 2,
      });
      if (res.status == 1) {
        // console.log(res.data.data)
        this.companyNotice = res.data.data;
      }
    },
    //  获取新闻分类接口()
    async init() {
      this.isLoading = true;
      const res = await postRegLogin();
      if (res.status == 1) {
        // console.log(res)
        this.navList1 = res.data;
        this.checkboxGroup1 = res.data;
      }
      this.isLoading = false;
    },
    // 首页新闻列表()  ==== 一般新闻
    async getnewsList() {
      this.isLoading = true;
      const res = await newsList();
      if (res.status == 1) {
        this.rightNews = res.data;
      }
      this.isLoading = false;
    },
    // 首页焦点新闻()
    async getImportantNews() {
      const res = await getImportNews();
      if (res.status == 1) {
        this.focusNews = res.data;
      }
    },
    // 首页图片()
    async getImg() {
      const res = await getIndexImg();
      if (res.status == 1) {
        this.imgs = res.data.image;
      }
    },
    // 获取首页重要新闻
    async getIndexFocusNews() {
      const res = await getFocusNews();
      if (res.status == 1) {
        this.mainNews = res.data;
      }
    },
    // 获取首页话题新闻
    async getIndexTopicNews() {
      const res = await getTopicNews();
      if (res.status == 1) {
        this.topicNews = res.data;
      }
    },
    // 获取图片
    async getImages() {
      const res = await getImagesData();
      if (res.status == 1) {
        console.log(res);
        this.messagesList = res.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/index1.css";
</style>