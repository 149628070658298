<template>
  <div class="home">
   <button @click="btnclick">按钮</button>
  </div>
</template>

<script>
// @ is an alias to /src

import {postRegLogin,postLogin} from "@/api/api.js"
export default {
  name: 'Home',
  components: {
   
  },
  methods: {
      async init(){
          const res = await postRegLogin({
              a: 0
          })
      },
      btnclick(){
         postRegLogin() 
      }
  }
}
</script>
