import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import index from '../views/index.vue'
import newsList from '../views/news-list.vue'
import newsDetail from '../views/news-detail.vue'
import login from '../views/login.vue'
import register from '../views/register.vue'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        component: index
    },
    {
        path: '/news',
        component: newsList
    },
    {
        path: '/newsDetail',
        component: newsDetail
    },
    {
        path: '/login',
        component: login
    },
    {
        path: '/register',
        component: register
    }
]

const router = new VueRouter({
    routes,
    mode: 'hash'
})

export default router