<template>
  <div class="news">
    <!-- header头部 -->
    <div class="">
		<div class="header-nav flex align-center justify-center">
			<div class="flex align-center hidden-xs" @click="goIndex" style="cursor:pointer">
				<img class="logo" src="@/assets/images/logo.jpg" alt=""   />
			</div>
			<div class="header-text h100 u-flex-1 w100">
                <img  :src="messagesList.length && messagesList[6].image" style="height:100%;width:100%" alt="">
            </div>
			<div class="flex align-center">
				<div class="vip-box flex align-center justify-center">
					<img class="margin-right-10 hidden-xs" src="../assets/images/my.png" alt="" />
					会員ログイン
				</div>
			</div>
		</div>
	</div>
	
	<!-- 新闻标题 -->
	<div class="news-title flex justify-center" style="padding:20px 0">
		<img class="date-img hidden-xs" src="../assets/images/my.png" alt="">
		<div class="title-text">会員申し込み（ 注意：「*」マークのあるものは必須 ）</div>
	</div>
	<!-- 新闻内容 -->
	<div class="news-content1 flex">
		<div class="news-left1 flex-direction align-center hidden-xs">
			<!-- 左上新闻item -->
			<div class="news-left1-content register-news-left-item">
				<div class="news-left-title">入会のご案内</div>
				<div class="news-left-item-box">
					<div class="news-left-item flex">
						<img class="news-icon" src="../assets/images/register-icon.png" alt="">
						<div>各条項のタイトルは、この条項の表現の主旨の使用を理解するためだけに、本協定条項の意味または解釈に影響しない、または制限しない。あなた自身の権益を守るために、各項目の具体的な表現をよく読むことをお勧めします。</div>
					</div>
				</div>
			</div>
		</div>
		<div class="news-right1 container register-box">
			<div class="choose-box flex">
				<div class="choose-item">
					<div>法人・個人：<span class="request">* </span> 
						<el-radio-group v-model="accountType">
							<el-radio  label="1">法人</el-radio>
							<el-radio label="2">個人</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="choose-item">
					<div>支払い方法：<span class="request">* </span> 
						<el-radio-group v-model="payType">
							<el-radio  label="1">郵便振替</el-radio>
							<el-radio label="2">銀行振込</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="choose-item">
					<div>入会时間：<span class="request">* </span> 
						<el-radio-group v-model="buyYears">
							<el-radio  label="1">1年</el-radio>
							<el-radio label="2">2年</el-radio>
							<el-radio label="3">3年</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="choose-item">
					<div>希望するサービス：<span class="request">*</span>
						<el-radio-group v-model="wishService">
							<el-radio  label="1">メール配信</el-radio>
							<el-radio label="2">WEB検索</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="hidden-xs" style="display:flex;">
                    <div class="id-number">
                        ID数：<span class="request">* </span> <input type="text" placeholder="请输入ID数" v-model="account">
                    </div>
                    <div class="id-number" style="margin-left:60px">
                        パスワード：<span class="request">* </span> <input type="password" placeholder="请输入パスワード" v-model="password">
                    </div>
                </div>
                <div class="visible-xs" style="display:flex-direction;">
                    <div class="id-number">
                        ID数：<span class="request">* </span> <input type="text" placeholder="请输入ID数" v-model="account">
                    </div>
                    <div class="id-number" style="margin-top:20px">
                        パスワード：<span class="request">* </span> <input type="password" placeholder="请输入パスワード" v-model="password">
                    </div>
                </div>
			</div>
			<!-- 用户信息 -->
			<div class="user-message flex-direction">
				<!-- item -->
				<div class="user-message-input flex" v-for="(item,index) in inputMessage" :key="index">
					<div class="user-message-input-text ">{{item.text}}:<span class="request" v-if="item.request">*</span> </div>
					<div @click="clicks(index)" class=" input-box">
						<input type="text" placeholder=" " v-model="item.value" :maxlength="item.maxlength">
						<div class="tip-text">（{{item.tipText}}）</div>
					</div>
				</div>
			</div>
			<div class="confirm-box flex justify-evenly">
				<div class="conform-item" v-for="(item,index) in conformText" :key="index" 
                @click="conformItemClick(index)" :style="index === conformIndex ? conformStyle : ''">{{item}}</div>
			</div>

		</div>
	</div>
		<foolter></foolter>
	
  </div>
</template>

<script>
import foolter from '../components/foolter.vue'
import {register,getImagesData} from '@/api/api'
export default {
  components: {
	  foolter
  },
  data() {
	  return {
		accountType: '1', //法人或个人
		payType:'', //邮政转账或银行转账
		buyYears:'',//入会时间
		wishService:'',//期望的服务
		account:'',//id
        password:'',//密码
	inputMessage:[{text:'会社名 · 氏名', request:true ,tipText:'漢字25文字以内', value: '', key: 'company_name',maxlength:25},
				  {text:'ふりがな' , request:true, tipText:'てらがな25文字以内', value: '', key: 'furigana',maxlength:25},
				   {text:'担当者· 氏名' ,responsiblePersonName:'', request:true, tipText:'漢字15文字以内', value: '', key: 'contact_name',maxlength:15},
				    {text:'部署名' ,departmentName:'', request:true, tipText:'漢字25文字以内', value: '', key: 'department_name',maxlength:25},
					 {text:'役曦名' ,role:'', request:true, tipText:'てらがな25文字以内', value: '', key: 'job_name',maxlength:25},
					  {text:'郵便番号' ,postalCode:'', request:true, tipText:'半角数字', value: '', key: 'post_code',maxlength:25},
					  {text:'住所' ,address:'', request:true, tipText:'漢字50文字以内', value: '', key: 'address',maxlength:50},
					   {text:'電話番号' ,phoneNumber:'', request:true, tipText:'半角数字', value: '', key: 'phone',maxlength:25},
					    {text:'FAX番号' ,faxNumber:'', request:true, tipText:'半角数字', value: '', key: 'fax_code',maxlength:25},
						 {text:'E-MAIL' ,email:'', request:true, tipText:'半角英数字50文字以内', value: '', key: 'email',maxlength:50}],
		conformText:['申し込み','取消'],
		conformIndex:0,
		conformStyle:'background-color:#004198;color:#ffffff',
         messagesList:[]

		  
	  }
  },
  created(){
       this.getImages()
  },
  methods: {
     goIndex(){
         this.$router.replace('/index')
     },
          // 获取图片
    async getImages(){
        const res = await getImagesData()
         if (res.status == 1) {
        console.log(res)
        this.messagesList = res.data
        
      }
    },
	clicks(index){
		console.log(this.inputMessage[index].userName)
	},
	conformItemClick(index){
		this.conformIndex = index
        if(index == 0){
            this.toRegister()
        }
        if(index == 1){
            history.go(-1)
        }
	},
    // 選択してください => 请选择  
    // 入力してください => 请输入
    async toRegister(){
       if (!this.accountType) return this.$message.error('選択してください法人・個人')
       if (!this.payType) return this.$message.error('選択してください支払い方法')
       if (!this.buyYears) return this.$message.error('選択してください入会时間')
       if (!this.wishService) return this.$message.error('選択してください希望するサービス')
       if (!this.account) return this.$message.error('入力してくださいID数')
       if (!this.password) return this.$message.error('入力してくださいパスワード')
       for (let item of this.inputMessage) {
           if (item.request) {
               if (!item.value) return this.$message.error('入力してください' + item.text);
           }
       }
        let email_send = this.wishService == 1 ? '1' : '0'
        let web_search = this.wishService == 2 ? '1' : '0'

       const res =  await register({
           account: this.account,
           password: this.password,
           pay_type: this.payType,
           buy_years: this.buyYears,
           account_type: this.accountType,
           company_name: this.getValue('company_name'),
           furigana: this.getValue('furigana'),
           department_name: this.getValue('department_name'),
           contact_name: this.getValue('contact_name'),
           job_name: this.getValue('job_name'),
           post_code: this.getValue('post_code'),
           phone: this.getValue('phone'),
           fax_code: this.getValue('fax_code'),
           email: this.getValue('email'),
           email_send,
           web_search
       })  
        // 成功回调  跳转登录页 输入账号密码登录
       if (res.status == 1) {
           this.$message.success('登録成功');
           this.$router.push('/login')
       } else {
           this.$message.error(res.msg);
       }
    },

    getValue(key) {
        return this.inputMessage.find(item => item.key == key).value
    }
  },
}
</script>

<style scoped>
@import '../assets/css/news-liat.css';

</style>