<template>
  <div class="news">
    <!-- header头部 -->
    <div class="">
      <div class="header-nav flex align-center justify-center">
        <div class="flex align-center hidden-xs">
          <img class="logo"
               src="@/assets/images/logo.jpg"
               style="cursor:pointer"
               @click="$router.replace('/index')" />
        </div>
        <div class="header-text h100 u-flex-1 w100">
          <img :src="messagesList.length && messagesList[6].image"
               style="height:100%;width:100%"
               alt="">
        </div>
        <div class="flex align-center">
          <div class="vip-box flex align-center"
               @click="vipLogin">
            <img class="margin-right-10 hidden-xs"
                 src="../assets/images/my.png"
                 alt="" />
            <div>
              <div v-if="!token">会員ログイン</div>
              <div v-else>{{userName}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新闻标题 -->
    <div class="news-title flex justify-center">
      <img class="date-img hidden-xs"
           src="../assets/images/today.png"
           alt="">
      <div class="title-text">今日の主要ニュース（ {{currentTime}}, {{nowWeek}} ）</div>
    </div>
    <!-- 新闻内容 -->
    <div class="news-content1 flex">
      <div class="news-left1 flex-direction align-center hidden-xs">
        <!-- 左上新闻item -->
        <div class="news-left1-content">
          <div class="news-left-title"
               @click="goFocusNewsListClick"
               style="cursor:pointer">{{titleList && titleList[1] && titleList[1].title}}</div>
          <div>
            <div class="news-left-item-box"
                 v-if="focusNews.length">
              <div class="news-left-item flex"
                   v-for="(item,index) in focusNews"
                   style="cursor:pointer"
                   :key="index"
                   @click="focusNewsClick(index)">
                <img class="news-icon"
                     src="../assets/images/collect.png"
                     alt="">
                <div>{{item.title}}</div>
              </div>
            </div>
            <div class="flex-direction align-center justify-center"
                 style="height:200px;"
                 v-else>
              <img style="width:100px;height:100px"
                   src="../assets/images/no-data.png"
                   alt="">
              <div style="margin-top:20px">データがありません</div>
            </div>
          </div>
          <div class="flex justify-center">
            <img class="news"
                 :src="messagesList.length && messagesList[1].image"
                 style="width:294px;height:210px"
                 alt="" />
          </div>
        </div>
        <div class="news-left1-content">
          <div class="news-left-title"
               style="cursor:pointer"
               @click="goHotNewsClick">{{titleList && titleList[0] && titleList[0].title}}</div>
          <div>
            <div class="news-left-item-box"
                 v-if="topicNews.length">
              <div class="news-left-item flex"
                   v-for="(item, index) in topicNews"
                   :key="index"
                   @click="hotNewsClick(index)"
                   style="cursor:pointer">
                <img class="news-icon"
                     src="../assets/images/burn.png"
                     alt="">
                <div>{{item.title}}</div>
              </div>
            </div>
            <div class="flex-direction align-center justify-center"
                 style="height:200px;"
                 v-else>
              <img style="width:100px;height:100px"
                   src="../assets/images/no-data.png"
                   alt="">
              <div style="margin-top:20px">データがありません</div>
            </div>
          </div>
          <div class="flex justify-center">
            <img class="news"
                 :src="messagesList.length && messagesList[0].image"
                 style="width:294px;height:210px"
                 alt="" />
          </div>
        </div>
      </div>
      <div class="news-right1 container">
        <div class="news-detail-text"
             v-if="type == 2">
          <div>
            <div class="news-right-title"
                 v-if="newsDetail.title">{{newsDetail.title}}</div>
            <div class="news-right-title"
                 v-if="newsDetail.name">{{newsDetail.name}}</div>
          </div>
          <div class="flex margin-top-20">
            <div>
              <div v-if="newsDetail.cate_name"
                   class="news-type">[ {{newsDetail.cate_name}} ]</div>
              <div v-else></div>
            </div>
            <div class="news-date">{{newsDetail.add_time}}</div>
          </div>
          <div class="news-detail-content-text"
               v-html="newsDetail.content">
            {{newsDetail.content}}
          </div>
        </div>
        <div v-else
             v-html="companyDetail">{{companyDetail}}</div>
      </div>
    </div>
    <foolter></foolter>

  </div>
</template>

<script>
import foolter from '../components/foolter.vue'
import {
  seeNewsDetail,
  getImportNews,
  getTopicNews,
  getcompanyNewsDetail,
  getImagesData,
  webCopyright,
  getTitles,
} from '../api/api.js'
export default {
  components: {
    foolter,
  },
  data() {
    return {
      id: '',
      token: '',
      newsDetail: {},
      focusNews: [], //焦点新闻
      topicNews: [], //中国话题
      nowWeek: '',
      currentTime: '',
      userName: localStorage.getItem('userName'),
      token: localStorage.getItem('token'),
      messagesList: [],
      companyDetail: '',
      type: '',
      titleList: [],
    }
  },
  created() {
    this.titles()
    this.getImages()
    this.id = this.$route.query.id
    this.token = localStorage.getItem('token')
    this.getIndexTopicNews()
    
    this.getImportantNews()
    // 获得当前日期
    let yy = new Date().getFullYear()
    let mm = new Date().getMonth() + 1
    let dd = new Date().getDate()
    this.currentTime = yy + '年' + mm + '月' + dd + '日'
    let wk = new Date().getDay()
    let weeks = [
      '日曜日',
      '月曜日',
      '火曜日',
      '水曜日',
      '木曜日',
      '金曜日',
      '土曜日',
    ]
    let week = weeks[wk]
    this.nowWeek = week
    // console.log(this.$route.query.detail)
    this.type = this.$route.query.detail
    this.typeDetail()
  },
  methods: {
    typeDetail() {
      switch (true) {
        case this.type == '1': //会社案内跳转
          this.companyData() //会社案内数据1
          break
        case this.type == '2': //别的跳转
          this.getseeNewsDetail()
          this.getCompanyDetail()
      }
    },
    //   获取标题
    async titles() {
      const res = await getTitles()
      if (res.status == 1) {
        console.log(res)
        this.titleList = res.data
      }
    },
    // 获取图片
    async getImages() {
      const res = await getImagesData()
      if (res.status == 1) {
        console.log(res)
        this.messagesList = res.data
      }
    },
    // 网站配置
    async companyData() {
      const res = await webCopyright()
      if (res.status == 1) {
        console.log(res)
        this.companyDetail = res.data.society
      }
    },
    // 焦点特辑点击进入列表页
    goFocusNewsListClick() {
      this.$router.push({
        path: '/news',
        query: { type: 'focus' },
      })
    },
    // hot话题新闻点击进入列表页
    goHotNewsClick() {
      this.$router.push({
        path: '/news',
        query: { type: 'hot' },
      })
    },
    vipLogin() {
      this.$router.push('/login')
    },
    async getseeNewsDetail() {
      const res = await seeNewsDetail({
        id: this.id,
        token: this.token,
      })
      if (res.status == 1) {
        this.newsDetail = res.data
      }else{
        //   this.type = 1 
        //   this.$message.error(res.msg)
      }
    },
    // 请求公司公告详情
    async getCompanyDetail() {
      const res = await getcompanyNewsDetail({
        id: this.id,
      })
      if (res.status == 1) {
        this.newsDetail = res.data
        // console.log(res)
      }
    },
    // 焦点新闻()
    async getImportantNews() {
      const res = await getImportNews()
      if (res.status == 1) {
        this.focusNews = res.data
      }
    },
    // 话题新闻点击
    hotNewsClick(index) {
      if (this.$isLogin()) {
        this.type = 2
        this.id = this.topicNews[index].id
        //   this.getseeNewsDetail()
        this.typeDetail()
      }
    },
    // 获取话题新闻
    async getIndexTopicNews() {
      const res = await getTopicNews()
      if (res.status == 1) {
        this.topicNews = res.data
      }
    },
    // 焦点新闻详情
    focusNewsClick(index) {
        if (this.$isLogin()) {
            this.type = 2
            this.id = this.focusNews[index].id
            //   this.getseeNewsDetail()
            this.typeDetail()
        }
    },
    firstNews() {},
    preNews() {},
  },
}
</script>

<style scoped>
@import '../assets/css/news-liat.css';
</style>