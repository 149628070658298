<template>
  <div class="login-content flex-direction">
    <!-- header头部 -->
    <div class="">
      <div class="header-nav flex align-center justify-center center-block">
        <div
          class="flex align-center hidden-xs"
          @click="goIndex"
          style="cursor: pointer"
        >
          <img class="logo" src="@/assets/images/logo.jpg" alt="" />
        </div>
        <div class="header-text h100 u-flex-1 w100">
          <img
            :src="messagesList.length && messagesList[6].image"
            style="height: 100%; width: 100%"
            alt=""
          />
        </div>
        <div class="flex align-center">
          <div class="vip-box flex align-center justify-center">
            <img
              class="margin-right-10 hidden-xs"
              src="../assets/images/my.png"
              alt=""
            />
            会員ログイン
          </div>
        </div>
      </div>
    </div>
    <!-- 登录 -->
    <div class="login flex-direction">
      <div class="login-box flex-direction">
        <div class="login-title">パスワード登録</div>
        <div class="login-title-line"></div>
        <div class="user-name-input">
          <input
            type="text"
            placeholder="アカウントを入力してください"
            v-model="userName"
          />
        </div>
        <div class="user-passward-input">
          <input
            type="password"
            placeholder="パスワードを入力してください"
            v-model="password"
          />
        </div>
        <div class="remember_password">
          <input
            type="checkbox"
            v-model="rememberPssword"
            v-on:change="clickPassword"
          />
          <span>记住密码</span>
        </div>
        <div class="flex justify-between margin-top-20 go-register">
          <div class="user-register" @click="accountRegister">
            アカウント登録
          </div>
          <div class="forget-passward">パスワードを忘れます</div>
        </div>
        <div class="login-in flex justify-center" @click="loginInClick">
          <div class="login-in-box">ログイン</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const Base64 = require("js-base64").Base64;
import { onload, getImagesData } from "@/api/api.js";
export default {
  components: {},
  data() {
    return {
      userName: "",
      password: "",
      token: "",
      messagesList: [],
      rememberPssword: false,
    };
  },
  created() {
    this.getImages();
    // 判断是否保存了密码
    this.ifPassword();
  },
  methods: {
    // 获取图片
    async getImages() {
      const res = await getImagesData();
      if (res.status == 1) {
        console.log(res);
        this.messagesList = res.data;
      }
    },
    accountRegister() {
      this.$router.push("/register");
    },
    loginInClick() {
      this.loginIn();
    },
    goIndex() {
      this.$router.replace("/index");
    },
    async loginIn() {
      const res = await onload({
        account: this.userName,
        password: this.password,
      });
      if (res.status == 1) {
        // 请求成功
        this.$message.success(res.msg);
        // 请求成功判断是否要记住密码
        if (this.rememberPssword) {
          // 存储登录信息
          this.setLoginInfo();
        } else {
          this.setCookie("account", "");
          this.setCookie("password", "");
        }
        this.$router.replace("/index");
        console.log(res.data);
        this.token = res.data;
        localStorage.setItem("token", this.token);
        localStorage.setItem("userName", this.userName);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 记住密码
    clickPassword(e) {},
    // 存储登录信息
    setLoginInfo() {
      // 记住密码操作
      this.setCookie("account", this.userName);
      let password = Base64.encode(this.password);
      this.setCookie("password", password);
    },
    // 存储Cookie
    setCookie(cName, value, expiredays) {
      var millisecond = new Date().getTime();
      var expiresTime = new Date(millisecond + 60 * 1000 * 20);
      document.cookie =
        cName + "=" + decodeURIComponent(value) + ";expires=" + expiresTime;
      console.log(cName + "=" + decodeURIComponent(value) + ";expires" + expiresTime);
    },
    // 获取Cookie
    getCookie(key) {
      if (document.cookie.length > 0) {
        var start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          var end = document.cookie.indexOf(";", start);
          if (end === -1) end = document.cookie.length;
          return unescape(document.cookie.substring(start, end));
        }
      }
      return "";
    },
    // 判断是否记住了密码
    ifPassword() {
      let account = this.getCookie("account");
      let password = Base64.decode(this.getCookie("password"));
      if (account) {
        this.userName = account;
        this.password = password;
        this.rememberPssword = true;
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/news-liat.css";
</style>