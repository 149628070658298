<template>
  <div class="foolter flex-direction align-center justify-center" style="color:#333">
      <div class="flex">
        <div v-for="(item,index) in links" style="margin-left:10px" :key="index"><a :href="item.url" style="color:#333">{{item.name}}</a></div>
      </div>
      <div>連絡先：{{cancat_kf}}</div>
      <div>{{copyright}}</div>
    <!-- <div>サイトの事記-サイトの招聘-著作権声明-インターネット視聴番組サービスの自主公約-広告</div>
    <div>サービス-友情リンク-誤り訂正メールボックス</div>
    <div>©2021 ChinaWave（Japan）Co..Ltd. all rights reserved.</div> -->
  
  </div>
</template>

<script>
import {webCopyright,getLink} from '@/api/api.js'
export default {
    data(){
        return{
            webBottom:'',
            cancat_kf:'',
            copyright:'',
            links:[]
        }
    },
    created(){
        this.getwebCopyright()
        this.getLinkData()
    },
    methods:{
        async getLinkData(){
            const res = await getLink()
            if(res.status == 1){
                console.log(res)
                 this.links = res.data
            }
        },
        async getwebCopyright(){
            const res = await webCopyright()
            if(res.status == 1){
                // console.log(res.data)
                this.cancat_kf = res.data.cancat_kf
                this.copyright = res.data.copyright
               
                    // 修改网站标题
                document.title = res.data.web_title
            }
        }
    }
};

</script>

<style scoped lang="scss">
.foolter {
 padding: 50px;
  line-height: 60px;
  color: #000000;
  font-size: 20px;

}
  @media screen and(max-width: 750px) {
    .foolter{
      padding:20px 10px;
      line-height: 20px;
      font-size: 10px;

    }
  }
</style>