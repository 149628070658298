import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'
import './common/css/public.css'
// import $ from 'jquery'
// import 'bootstrap'
// window.jQuery = $
// jQuery = $
// window.$ = window.jQuery

Vue.prototype.$isLogin = () => {
    let token = localStorage.getItem('token')

    if (!token) {
        ElementUI.Message({
            message: 'まず登録してください',
            type: 'error'
        })
    }

    return Boolean(token)
}

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')