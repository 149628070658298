import {
	post,
	get
} from './service.js'


// 获取新闻分类（nav列表）
export function postRegLogin(data) {
	return get('/index/index/getNewsCategory', data)
}
// 获取首页贴图
export function getIndexImg(data){
    return get('/index/index/getHomeImages',data)
}
//网站配置
export function webCopyright(data) {
	return get('/index/index/getWebSystemInfo', data)
}


// 获取首页新闻列表，带数据，一般新闻
export function newsList(data) {
	return get('/index/index/getHomeNewsList', data)
}

// 点击分类获取新闻列表,跳转到列表页
export function getListNews(data){
    return get('index/index/getNewsByCate',data)
}

// 获取首页焦点新闻
export function getImportNews(data){
    return get('/index/index/getSpecialNews',data)
}
//获取焦点新闻列表 (参数，页码)
export function getImportantNewsList(data){
    return get('/index/index/getSpecialNewsList',data)
}

// 获取重要新闻列表
export function getFocusNewsList(data){
    return get('/index/index/getMajorNewsList',data)
}
// 获取首页重要新闻
export function getFocusNews(data){
    return get('/index/index/getMajorNews',data)
}
// 获取话题新闻列表 参数页
export function getTopicNewsList(data){
    return get('/index/index/getTopicNewsList',data)
}
//获取话题新闻
export function getTopicNews(data){
    return get('/index/index/getTopicNews',data)
}
// 获取所有新闻
export function getCommonNews(data){
    return get('/index/index/getAllNews',data)
}

// 注册账号
export function register(data){
    return post('/index/login/register',data)
}
// 登录 参数：账号密码
export function onload(data){
    return post('/index/login/login',data)
}
// 搜索新闻 参数：keyword和token 
export function searchNews(data){
    return get('/index/news/searchNews',data)
}
// 查看新闻详情 参数：id和token
export function seeNewsDetail(data){
    return get('/index/news/getNewsDetail',data)
}
// 公司简介
export function getcompanyProfile(data){
    return get('/index/index/getAnnoun',data)
}
//公司简介列表
export function getcompanyProfileList(data){
    return get('/index/index/getAnnounList',data)
}
// 获取公司简介详情
export function getcompanyNewsDetail(data){
    return get('/index/index/getAnnounDetail',data)
}
// 获取首页图片
export function getImagesData(data){
    return post('/index/index/getImages',data)
}
// 友情链接
export function getLink(data){
    return post('/index/index/getHomeLink',data)
}
//获取标题
export function getTitles(data){
    return post('/index/index/getHomeTitles',data)
}

// https://yasins.cn/index/news/searchNews?keyword=测试新闻&token=z5RpEMbTqIonBxDu2nDAKOp
// https://yasins.cn/index/news/getAnnounDetail


