<template>
  <div class="header-nav col-12 center-block">
    <div class="flex align-center justify-between ">
      <div class="flex align-center ">
        <img class="logo hidden-xs"
             src="@/assets/images/logo.jpg"
             style="cursor:pointer"
               @click="$router.replace('/index')"
             alt="">
        <slot>
          <div class="tabs flex">
            <div class="tabs-item"
                 v-for="(item,index) in tabs"
                 :key="index"
                 @click="navClick(index)"
                 :style="index === isChoose? isChooseStyle :''">
              <div class="tabs-item-box">{{item.text}}</div>
            </div>
          </div>
        </slot>
      </div>
      <div class="swiper-search flex align-center"  @click="$router.push('/news')">
        <i class="el-icon-search hidden-xs"></i>
        <input 
               type="search"
               style="cursor:pointer"
               class="swiper-search-box"
               placeholder="キーワードを入力してください" disabled />
      </div>
      <div class="flex align-center">
        <div class="hidden-xs search flex justify-center align-center" style="cursor:pointer" @click="$router.push('/news')">
          <i class="el-icon-search"></i>
          検索
        </div>
        <div class="vip-box flex align-center justify-center"
             @click="vipLogin">
          <img class="hidden-xs"
               style="width:24px;height:24px;margin-right:10px"
               src="@/assets/images/my.png"
               alt="">
          <div class="text-overflow" style="width:90px">
            <div v-if="!token">会員ログイン</div>
            <div v-else>{{userName}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      tabs: [{ text: 'MENU' }, { text: 'ニュース全般', path: '/news' }],
      isChooseStyle: 'border-bottom: 4px solid #004098;',
      isChoose: 0,
      token: localStorage.getItem('token'),
      userName: localStorage.getItem('userName'),
    
    }
  },
  
  methods: {
    navClick(index) {
      this.isChoose = index
      let news = this.tabs[index].path
      if (this.tabs[index].path) {
        this.$router.push({
          path: news,
          query: { type: 'all' },
        })
      }
    },
    vipLogin() {
      this.$router.push('/login')
    },
    

  },
}
</script>

<style lang="scss" scoped>
.header-nav {
  max-width: 1200px;
}
.logo {
  height: 80px;
  margin-right: 50px;
}
.tabs {
  flex: 1;
}
.tabs-item {
  margin-right: 40px;
}
.tabs-item-box {
  padding-bottom: 10px;
  color: #004198;
  cursor: pointer;
}
.search {
  height: 80px;
  padding: 0 40px;
  border-left: 1px dashed #004198;
  color: #004198;
}
.vip-box {
  // width: 200px;
  padding: 0 24px;
  height: 80px;
  background-image: linear-gradient(90deg, #004098 0%, #0070cf 100%);
  color: #ffffff;
}

/* swiper */

.index .swiper-search {
  height: 40px;
  border: 1px solid #004198;
  border-radius: 30px;
  padding: 0 20px;
  width: 300px;
  margin-right: 20px;
}

.index .swiper-search-box {
  // flex: 1;
  background-color: transparent;
  border: 0;
  outline: none;
  color: #004198;
  font-size: 16px;
  width: 100%;
  margin-left: 10px;
}

.index .swiper-search-box::placeholder {
  color: #004198;
  font-size: 16px;
}
.el-icon-search {
  cursor: pointer;
}
@media screen and (max-width: 750px) {
  .header-nav {
    height: 60px;
    padding-left: 0;
  }
  .tabs-item {
    margin-right: 10px;
    width: 50px;
    height: 40px;
  }
  .search {
    height: 60px;
    padding: 0 10px;
  }
  .vip-box {
    padding: 2px;
    height: 60px;
    width: 60px;
  }
  .tabs-item-box {
    padding-bottom: 0;
  }
  /* swiper */

  .index .swiper-search {
    height: 30px;
    padding: 0 2px;
    width: 160px;
    margin-right: 4px;
  }

  .index .swiper-search-box {
    // flex: 1;
    font-size: 12px;
    width: 100%;
  }

  .index .swiper-search-box::placeholder {
    font-size: 10px;
  }
}
</style>