<template>
  <div class="news w100">
    <!-- header头部 -->
    <div class="w100">
      <div class="header-nav flex align-center w100">
        <div class="flex align-center hidden-xs">
          <img class="logo"
               src="@/assets/images/logo.jpg"
               style="cursor:pointer"
               @click="$router.replace('/index')"
               alt="" />
        </div>
        <div class="header-text h100 u-flex-1 w100">
            <img :src="messagesList.length && messagesList[6].image" style="height:100%;width:100%" alt="">
        </div>
        <div class="flex align-center">
          <div class="vip-box flex align-center" style="height:80rpx"
               @click="vipLogin">
            <img class="margin-right-10 hidden-xs"
                 src="@/assets/images/my.png"
                 alt="" />
            <div class="h100 flex align-center justify-center">
              <div v-if="!token">会員ログイン</div>
              <div v-else>{{userName}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="search-box">
      <div class="container flex-direction ">
        <div class="search">
          <div class="search-title">サイト全体をキーワードで検索</div>
          <div class="flex">
            <input type="search"
                   v-model="keyWords"
                   class="col-md-8 col-xs-8 search-input"
                   placeholder="キーワードを入力してください"
                   >
            <div class="col-md-2 col-xs-4 search-input-text flex align-center justify-center"
                 @click="searchBtn">
              <img class="search-iocn hidden-xs"
                   style="margin-right:10px;width:20px;height:26px"
                   src="@/assets/images/search.png"
                   alt="">
              検索する
            </div>
          </div>
          <!-- <div class="flex search-tip">
            <div class="search-items"
                 v-for="(item,index) in searchItems"
                 :key="index"
                 @click="searchItemClick(index)">>{{item.text}}</div>

          </div> -->
        </div>
      </div>
    </div>
    <!-- 新闻标题 -->
    <div class="news-title flex justify-center">
      <img class="date-img hidden-xs"
           src="../assets/images/today.png"
           alt="">
      <div class="title-text">今日の主要ニュース（ {{currentTime}}，{{nowWeek}} ）</div>
      <div class="hidden-xs"
           style="cursor:pointer">
        <img style="width:20px;height:20px"
             class="change-img"
             src="../assets/images/first-page.png"
             alt=""
             @click="goFirstPage()">
        <img style="width:17px;height:19px;margin:0 30px"
             class="change-img"
             src="../assets/images/pre-page.png"
             alt=""
             @click="prePage()">
        <img style="width:17px;height:19px;margin-right:30px"
             class="change-img"
             src="../assets/images/next-page.png"
             alt=""
             @click="nextPage()">
        <img style="width:20px;height:20px"
             class="change-img"
             src="../assets/images/last-page.png"
             alt=""
             @click="golastPage()">
      </div>
    </div>
    <!-- 新闻内容 -->
    <div class="news-content1 flex">
      <div class="news-left1 flex-direction align-center hidden-xs">
        <!-- 左上新闻item -->
        <div class="news-left-content">
          <div class="news-left-title"
               @click="ShowFocusNewsList"
               style="cursor:pointer">{{titleList && titleList[1] && titleList[1].title}}</div>
          <div>
            <div class="news-left-item-box"
                 v-if="focusNews.length" style="min-height:200px">
              <div class="news-left-item flex"
                   style="cursor:pointer"
                   v-for="(item,index) in focusNews"
                   :key="index"
                   @click="focusNewsClick(index)">
                <img class="news-icon"
                     src="../assets/images/collect.png"
                     alt="">
                <div>{{item.title}}</div>
              </div>
            </div>
            <div class="flex-direction align-center justify-center"
                 style="height:200px;"
                 v-else>
              <img style="width:100px;height:100px"
                   src="../assets/images/no-data.png"
                   alt="">
              <div style="margin-top:20px">データがありません</div>
            </div>
          </div>
           <div class="flex justify-center">
               <img class="news"
                     :src="messagesList.length && messagesList[5].image"
                     style="width:294px;height:210px"
                     alt="" />
           </div>
        </div>
        <!-- 左下新闻列表 -->
        <div class="news-menu">
          <div class="news-left-title">MENU</div>
          <div class="news-menu-content flex-direction  align-center">
            <div class="news-menu-item"
                 v-for="(item,index) in leftMenu"
                 :key="index"
                 @click="menuItemClick(index)"
                 :style="MENUcurrent === index? MRNUstyle : ''">{{item.name}}</div>

          </div>
        </div>
      </div>
      <div class="news-right1 container">
        <!-- 新闻 -->
        <div v-loading="isLoading"
             style="min-height:500px">
          <div class="news-right-content"
               v-for="(item,index) in commonNews"
               :key="index"
               @click="moreNewsClick(index)" 
               >
            <div>
              <div>
                    <div v-if="item.title" class="news-right-title">{{item.title}} {{item.name}}</div>
                    <div v-if="item.name" class="news-right-title">{{item.title}} {{item.name}}</div>
              </div>
              
              <div class="flex">
                <div>
                  <div v-if="item.cate_name" class="news-type">[ {{item.cate_name}} ]</div>
                  <div v-else class="news-type">[{{codeType}}]</div>
                </div>
                <div class="news-date">{{item.add_time}}</div>
              </div>
              <div class="news-right-text">
                {{item.description}}
                <div>
                    <span v-if="type == 'company' || type == 'Notice'" style="margin-left:0" @click="moreNewsClick1(index)" >MORE>></span>
                    <span v-else style="margin-left:0" @click="moreNewsClick(index)" >MORE>></span>
                </div>
              </div>
            </div>

          </div>
          <div class="flex-direction align-center justify-center"
               style="height:500px"
               v-if="commonNews.length == 0">
            <img style="width:100px;height:100px"
                 src="../assets/images/no-data.png"
                 alt="">
            <div style="margin-top:20px">データがありません</div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="page-button1 w100 hidden-xs">
          <el-pagination :current-page="page"
                         prev-text="上一页"
                         next-text="下一页"
                         background
                         :page-size="10"
                         :pager-count="7"
                         layout="prev, pager, next"
                         :total="totalNews"
                         @current-change="currentPageChange"></el-pagination>
        </div>
        <div class="page-button2 visible-xs">
          <el-pagination background
                         :pager-count="5"
                         :current-page="page"
                         :page-size="10"
                         @current-change="currentPageChange"
                         layout="prev, pager, next"
                         :total="totalNews">
          </el-pagination>
        </div>
      </div>

    </div>
    <foolter></foolter>

  </div>
</template>

<script>
import foolter from '../components/foolter.vue'
import {
  getListNews,
  postRegLogin,
  getCommonNews,
  getImportNews,
  getFocusNewsList,
  searchNews,
  getTopicNewsList,
  getImportantNewsList,
  getcompanyProfileList,
  getImagesData,
  getTitles
} from '@/api/api.js'
export default {
  components: {
    foolter,
  },
  data() {
    return {
      leftMenu: [],
      MRNUstyle: 'border-bottom:4px solid #0070cf;color:#0070cf',
      MENUcurrent: 0,
      cid: '',
      commonNews: [],
      searchItems: [
        { text: '冬季オリンピック' },
        { text: '新冠の疫病' },
        { text: '日本の地震' },
        { text: '科学技術' },
      ],
      page: 1,
      pages: 2,
      newsLength: 0,
      detailNewsId: '',
      focusNews: [], //焦点
      type: '',
      currentTime: '',
      nowWeek: '',
      token: '',
      keyWords: '',
      isLoading: false,
      totalNews: 0,
      lastPage: 0,
      userName: localStorage.getItem('userName'),
      token: localStorage.getItem('token'),
      codeType:'',
      messagesList:[],
      titleList:[]

    }
  },

  created() {
    this.cid = this.$route.query.Id //得到点击传进来的id
    this.getMENU() //获取menu
    this.getImportantNews() //获取焦点新闻
    this.type = this.$route.query.type
    this.judgeType()
    // 获得当前日期
    let yy = new Date().getFullYear()
    let mm = new Date().getMonth() + 1
    let dd = new Date().getDate()
    this.currentTime = yy + '年' + mm + '月' + dd + '日'
    let wk = new Date().getDay()
    let weeks = [
      '日曜日',
      '月曜日',
      '火曜日',
      '水曜日',
      '木曜日',
      '金曜日',
      '土曜日',
    ]
    let week = weeks[wk]
    this.nowWeek = week
    if(this.type == 'Notice'){
        this.codeType = '公告する'
    }else{
       this.type = this.$route.query.type
    }
    this.getImages()
    this.titles()
  },
  methods: {
    // ------------------列表-------------------------------------
     //   获取标题
    async titles(){
        const res = await getTitles()
        if(res.status == 1){
            console.log(res)
            this.titleList = res.data
        }
    },
    //  1 点击menu请求数据列表
    menuItemClick(index) {
      this.type == 'menu'
      this.page = 1
      this.MENUcurrent = index
      //   this.type = 'menu'
      this.cid = this.leftMenu[index].id
      this.getNews()
    },
    // 2.跳转过来 获取新闻列表 各种nav点击获取新闻列表
    async getNews() {
      this.isLoading = true
       this.type == 'common'
      const res = await getListNews({ cid: this.cid, page: this.page })
      if (res.status == 1) {
        this.commonNews = res.data.data
        this.newsLength = this.commonNews.length
        this.totalNews = res.data.total
        this.lastPage = res.data.last_page
        console.log(this.commonNews)
      } else {
        this.commonNews = []
      }
      this.isLoading = false
    },

    // 获取公司公告
     async getCompanyNoticeList(){
         this.isLoading = true
        const res = await getcompanyProfileList({
             page: this.page,
             type:2
        })
        if (res.status == 1) {
            // console.log(res)
        this.commonNews = res.data.data
        this.newsLength = this.commonNews.length
        this.totalNews = res.data.total
        this.lastPage = res.data.last_page
        console.log(this.detailNewsId)
      } else {
        this.commonNews = []
      }
      this.isLoading = false
    },
    //   3.改变页码请求数据
    currentPageChange(e) {
      this.page = e
      this.judgeType() //判断type并请求数据
    },
    //4. 获取重要新闻列表
    async getImportNewsList() {
      this.isLoading = true
      const res = await getFocusNewsList({
        page: this.page,
      })
      if (res.status == 1) {
        this.totalNews = res.data.total
        this.commonNews = res.data.data
        this.lastPage = res.data.last_page
      } else {
        this.commonNews = []
      }
      this.isLoading = false
    },
    // 5.获取全部新闻列表
    async getAllNews() {
      this.isLoading = true
      const res = await getCommonNews({
        page: this.page,
      })
      if (res.status == 1) {
        console.log(res)
        this.totalNews = res.data.total
        //  console.log(this.totalNews)
        this.totalNews = res.data.total
        this.commonNews = res.data.data
        this.lastPage = res.data.last_page
      }
      this.isLoading = false
    },
    // 7.获取hot热点新闻列表
    async getTopicNewsLists() {
      const res = await getTopicNewsList({
        page: this.page,
      })
      if (res.status == 1) {
        this.totalNews = res.data.total
        this.commonNews = res.data.data
        this.lastPage = res.data.last_page
      } else {
        this.commonNews = []
      }
    },
    // 8.获取焦点新闻列表
    async getFocusNewsList() {
      let res = await getImportantNewsList({
        page: this.page,
      })
      if (res.status == 1) {
        this.totalNews = res.data.total
        this.commonNews = res.data.data
        this.lastPage = res.data.last_page
      } else {
        this.commonNews = []
      }
    },
    // 9.点击焦点获取焦点新闻列表
    ShowFocusNewsList() {
      this.page = 1
      this.type = 'focus'
      this.getFocusNewsList()
    },
    //6. 搜索新闻列表
    searchItemClick(index) {
      this.page = 1
      this.keyWords = this.searchItems[index].text
    },
    async searchNewsList() {
      if (this.$isLogin()) {
        this.isLoading = true
        this.type = 'search'
        this.token = localStorage.getItem('token')
        //   console.log(this.token)
        const res = await searchNews({
          keyword: this.keyWords,
          token: this.token,
        })
        console.log(res)
        if (res.status == 1) {
          // this.totalNews = res.data.total
          this.commonNews = res.data
          //  this.lastPage = res.data.last_page
        } else {
          this.$message.error(res.msg)
        }
        this.isLoading = false
      }
    },
    searchBtn() {
      this.searchNewsList()
    },
    // -------------------------------详情页---------------
    //   点击更多跳转到详情页
    moreNewsClick(index) {
      if (this.$isLogin()) {
        this.detailNewsId = this.commonNews[index].id
        this.$router.push({
          path: '/newsDetail',
          query: { id: this.detailNewsId, detail:2},
        })
      }
    },
     moreNewsClick1(index) {
      this.detailNewsId = this.commonNews[index].id
        this.$router.push({
          path: '/newsDetail',
          query: { id: this.detailNewsId, detail: 2 },
        })
    },
    
    vipLogin() {
      this.$router.push('/login')
    },
    
    // 焦点新闻点击进入详情页
    focusNewsClick(index) {
      if (this.$isLogin()) {
        this.detailNewsId = this.focusNews[index].id
        this.$router.push({
          path: '/newsDetail',
          query: { id: this.detailNewsId ,detail:2},
        })
      }
    },

    //   得到menu列表
    async getMENU() {
      this.isLoading = true
      const res = await postRegLogin()
      if (res.status == 1) {
        this.leftMenu = res.data
      }
      this.isLoading = false
    },
    // 焦点新闻列表
    async getImportantNews() {
      this.isLoading = true
      const res = await getImportNews()
      // console.log(res)
      if (res.status == 1) {
        // console.log(res)
        this.focusNews = res.data
      }
      this.isLoading = false
    },
    judgeType() {
      switch (true) {
        case this.type == 'import':
          this.getImportNewsList() //重要新闻
          break
        case this.type == 'all':
          this.getAllNews() //所有新闻
          break
        case this.type == 'common':
          this.getNews() //点击nav新闻
          break
        case this.type == 'search': //搜索新闻
          this.searchNewsList()
          break
        case this.type == 'hot': //热点新闻
          this.getTopicNewsLists()
          break
        case this.type == 'focus': //焦点新闻
          this.getFocusNewsList()
          break
        case this.type == 'Notice' : //公司公告
        this.getCompanyNoticeList()
        break
      }
    },
     // 获取图片
    async getImages(){
        const res = await getImagesData()
         if (res.status == 1) {
        console.log(res)
        this.messagesList = res.data
        
      }
    },
    // 去第一页
    goFirstPage() {
      this.page = 1
      this.judgeType()
    },
    prePage() {
      if (this.page == 1) {
        this.$message.error('もう1ページ目です')
      } else {
        this.page -= 1
      }

      this.judgeType()
    },
    nextPage() {
      if (this.page == this.lastPage) {
        this.$message.error('もう最後のページです')
      } else {
        this.page += 1
      }
      this.judgeType()
    },
    golastPage() {
      this.page = this.lastPage
      this.judgeType()
    },
  },
}
</script>

<style scoped>
@import '../assets/css/news-liat.css';
</style>